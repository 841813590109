import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./privateRoute";

//Inove Clube Main Page
import { Main } from "../pages/main";

//Public Pages
import { Login } from "../pages/login";
import { RegisterDatasPerson } from "../pages/registerDatasPerson";
import { RegisterAddressPerson } from "../pages/registerAddressPerson";
import { Recover } from "../pages/Recover";
import { RecoverPassword } from "../pages/recoverPassword";
import { Ui } from "../pages/ui";
// import { AdministrativePanel } from '../pages/administrativePanel'

import { IndividualMarketPlace } from "../pages/individualMarketPlace";
import { Wholesale } from "../pages/Wholesale";
import { Menu } from "../menus/Menu";

//Private Pages
import { Profile } from "../pages/profile";
import { EditProfile } from "../pages/editProfile";
import { Address } from "../pages/address";
import { Orders } from "../pages/orders";
import { ShoppingScreen } from "../shopping";
import { UserCardRegister } from "../pages/userCardRegister";
import { Cards } from "../pages/cards";

//Undefined Routes
import { RouteUndefined } from "../routeUndefined";

import { Test } from "../test";
import { OrderManager } from "../pages/orderManager";
import { Fidelity } from "../pages/fidelity";

const AdministrativePanel = lazy(() => import("../pages/administrativePanel"));

const Routes = () => (
	<Switch>
		<Route path="/" exact component={Main} />
		<Route path="/menu/:business" component={Menu} />
		<Route path="/store/:matrixBusiness" component={IndividualMarketPlace} />
		<Route path="/login" component={Login} />
		<Route exact path="/register/:data" component={RegisterDatasPerson} />
		<Route exact path="/register" component={RegisterDatasPerson} />
		<Route path="/registeraddress" component={RegisterAddressPerson} />
		<Route path="/password/:token" component={RecoverPassword} />
		<Route path="/recover" component={Recover} />
		<Route path="/b2b/:business/:cnpj" component={Wholesale} />
		<PrivateRoute path="/cards" component={Cards} />
		<PrivateRoute path="/fidelity" component={Fidelity} />
		<PrivateRoute path="/cardregister" component={UserCardRegister} />
		<PrivateRoute path="/shopping" component={ShoppingScreen} />
		<PrivateRoute path="/profile" component={Profile} />
		<PrivateRoute path="/address" component={Address} />
		<PrivateRoute path="/editprofile" component={EditProfile} />
		<PrivateRoute path="/orders" component={Orders} />
		<PrivateRoute path="/order-manager" component={OrderManager} />
		<PrivateRoute path="/ui" component={Ui} />

		<Suspense fallback={<div>Carregando...</div>}>
			<PrivateRoute path="/adm" component={AdministrativePanel} />
		</Suspense>

		<Route path={process.env.REACT_APP_DEVELOPMENT ? "/test" : ""} component={Test} />

		<Route path="*" component={RouteUndefined} />
	</Switch>
);

export default Routes;
