import { useContext, createContext, useState, useEffect } from "react";
import { esES } from "../Utils/translations/es-ES";
import { ptBR } from "../Utils/translations/pt-BR";

const TranslatorContext = createContext();
export const TranslatorProvider = ({ children }) => {
	const [currentLanguage, setCurrentLanguage] = useState("pt-BR");

	function choseLanguage(language) {
		setCurrentLanguage(language);
		localStorage.setItem("language", language);
	}

	function getText(key) {
		const keys = key.split(".");
		let translation = currentLanguage === "pt-BR" ? ptBR : esES;

		for (let i = 0; i < keys.length; i++) {
			translation = translation[keys[i]];
			if (!translation) return key;
		}

		return translation;
	}

	useEffect(() => {
		const localLanguage = localStorage.getItem("language");
		if (localLanguage) setCurrentLanguage(localLanguage);
	}, []);

	return (
		<TranslatorContext.Provider
			value={{
				currentLanguage,
				choseLanguage,
				getText,
			}}
		>
			{children}
		</TranslatorContext.Provider>
	);
};

export function useTranslator() {
	const context = useContext(TranslatorContext);
	if (!context) throw new Error("useTranslator must be used within TranslatorProvider");
	return context;
}
