// ---- Styles ---- //
import { Loader, Container } from "./style";

// ---- Main Component ---- //
const SpaceLoader = ({ colorLoader = "#535354", width = "auto", height = "auto" }) => {
  return (
    <Container width={width} height={height}>
      <Loader colorLoader={colorLoader} />
    </Container>
  );
};

// ---- Export ---- //
export { SpaceLoader };
