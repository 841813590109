import { createContext, useContext, useState, useEffect } from "react";

// Create a context
const DialogContext = createContext();

// Create a provider wrapper component
export const DialogProvider = ({ children, title, loading, openController }) => {
	const [isOpen, setIsOpen] = useState(openController);

	useEffect(() => {
		setIsOpen(openController);
	}, [openController]);

	function openDialog() {
		setIsOpen(true);
	}

	function closeDialog() {
		setIsOpen(false);
	}

	return (
		<DialogContext.Provider
			value={{ isOpen, openDialog, closeDialog, title, loading, openController }}
		>
			{children}
		</DialogContext.Provider>
	);
};

// Create a custom hook that uses the context
export const useDialogContext = () => {
	const context = useContext(DialogContext);
	if (!context) throw new Error("useDialogContext must be used within a DialogProvider");
	return context;
};
