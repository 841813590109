// ---- Imports ---- //
import { SpaceLoader } from "../spaceLoader";
import { XCloseButton } from "../xCloseButton";
import { DialogProvider, useDialogContext } from "./context";

// ---- Styles ---- //
import {
	Container,
	Modal,
	Backdrop,
	Title,
	DialogHeader,
	DialogBody,
	DialogFooter,
} from "./styles";

/** Main Component
 * @param {ReactNode} children - The content of the dialog, usually DialogTrigger and DialogContent
 * @param {string} title - The title of the dialog
 * @param {boolean} loading - If true, the dialog will show a loading spinner instead of the children
 */
const Dialog = ({ children, title, loading, openController = false }) => {
	return (
		<DialogProvider title={title} loading={loading} openController={openController}>
			{children}
		</DialogProvider>
	);
};

/** Dialog Trigger
 * @param {ReactNode} children - The content that will open the dialog when clicked
 */
const DialogTrigger = ({ children }) => {
	const { openDialog } = useDialogContext();
	return (
		<div onClick={openDialog} onKeyDown={openDialog}>
			{children}
		</div>
	);
};

/** Dialog Closer
 * @param {ReactNode} children - The content that will close the dialog when clicked
 * @param {function} toClose - The function that will close the dialog
 */
const DialogCloser = ({ children, toClose }) => {
	const { closeDialog } = useDialogContext();
	async function handleClose() {
		if (toClose) await toClose();
		closeDialog();
	}
	return (
		<div onClick={handleClose} onKeyDown={handleClose}>
			{children}
		</div>
	);
};

/** Dialog Content
 * @param {ReactNode} children - The content of the dialog, usually DialogHeader, DialogBody, and DialogFooter
 */
const DialogContent = ({ children }) => {
	const { title, loading, isOpen, closeDialog } = useDialogContext();

	if (!isOpen) return null;
	return (
		<Container>
			<Modal>
				<DialogHeader>
					<Title>{title}</Title>
					<XCloseButton toClose={closeDialog} />
				</DialogHeader>
				{loading ? <SpaceLoader width="100%" height="100%" /> : children}
			</Modal>
			<Backdrop onClick={closeDialog} />
		</Container>
	);
};

// ---- Export ---- //
export {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogBody,
	DialogFooter,
	DialogCloser,
};
