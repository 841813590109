import { BiUser } from "react-icons/bi";
import { Button } from "../ui/base-components";
import { Dialog, DialogContent } from "../ui/dialog";
import { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { api } from "../../services/api";

const InputLog = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 2px 4px var(--colorShadow), 0 8px 16px var(--colorShadow);
    margin-bottom: 10px;
    padding: 12px;
    border: 1.5px solid ${(props) => (props.alertUserToFillInput ? "red" : "var(--colorBorderInput)")};
    border-radius: 0.25rem;

    .visibility{

        @media (min-width: 960px){
            cursor: pointer;
        }
    }

    .visibilityOff{
        color: var(--colorButton);
        
        @media (min-width: 960px){
            cursor: pointer;
        }
    }

    input{
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        color: black;
        border: none;
    }
    svg{
        margin-right: 5px;
        color: gray;
    }
`;

export function UpdateUserCpf({ open, updateOpen }) {
	const [cpf, setCpf] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	function handleUpdateCpf(e) {
		e.preventDefault();
		setIsLoading(true);
		// transform cpf like 123.456.789-10 to 12345678910
		const formattedCpf = cpf.replace(/\D/g, "");
		if (formattedCpf.length !== 11) {
			toast.info("CPF inválido");
			return;
		}

		api
			.patch(
				`/updateCpfPerson/${formattedCpf}/${localStorage.getItem("@Inoveclube:token")}`,
			)
			.then(() => {
				document.location.reload();
			})
			.catch((error) => {
				console.log(error);
				toast.warn("Algo deu errado ao atualizar o cpf, tente novamente mais tarde.");
			})
			.finally(() => {
				setIsLoading(false);
			});
	}
	return (
		<Dialog openController={open} title="Atualizar CPF">
			<DialogContent>
				<p>Atualize seu cpf para fazer o pedido</p>
				<form onSubmit={handleUpdateCpf}>
					<InputLog>
						<BiUser />
						<input
							type="tel"
							placeholder="CPF"
							maxLength={14}
							name="CpfInput"
							disabled={isLoading}
							value={cpf}
							onChange={(event) => setCpf(event.target.value)}
						/>
					</InputLog>
					<Button
						size={20}
						maxWidth={200}
						className="mt-3 center"
						disabled={isLoading}
						type="submit"
					>
						Salvar
					</Button>
				</form>
			</DialogContent>
		</Dialog>
	);
}
